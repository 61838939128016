import './About.css';
import './SharedStyles.css';
import bedroomMe from './images/bedroomMe.jpg';
import collegeMe from './images/collegeMe.jpg';

function About() {
    return (
        <div className="fadedInOutlet" id="screen">
            <div className="screenHeading">
                <h1 className="bigText">About Me</h1>
                <div className="pictureAndText">
                    <img src={collegeMe} className="aboutIMGs" alt="college"/>
                    <p className="smallText">As the son of an indie game designer, I fell in love with computing at an early age. When I turned 18, I enrolled at Oregon Tech in Klamath Falls Oregon, where I majored in Software Engineering. After several years in college, I chose instead to pursue a career in radio broadcasting. I spent nine fulfilling years in radio, including three years with the top-rated radio group in Klamath Falls and nine years with the top-rated radio group in my hometown of Medford.</p>
                </div>
                <div className="pictureAndText">
                    <img src={bedroomMe} className="aboutIMGs" alt="bedroom"/>
                    <p className="smallText" id="leftSmallText">Since September 2023, I have freelanced as a full-stack web developer. I have experience designing mobile-friendly web applications in HTML & CSS, JavaScript and the ReactJS ecosystem, PHP, plus a flurry of back-end languages including JavaScript on the NodeJS runtime, Java, and Python.</p>
                </div>
            </div>
        </div>
    );
}

export default About;