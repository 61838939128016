import './SoftwarePortfolio.css';
import './SharedStyles.css';

function SoftwarePortfolio() {
    return (
        <div className="fadedInOutlet" id="screen">
            <div className="screenHeading">
                <h1 className="bigText">Hi! I'm Matt!</h1>
                <h3 className="mediumText">I am a freelance web developer in Oregon, seeking work!</h3>
                <div className="smallTextParagraph">
                    <p className="smallText">Since September 2023, I have worked as a freelancer for UniverCity Inc. I am developing a web app called <a href="http://uvcsandbox.com">Access 2 Care</a> that will help Florida Keys residents connect with local healthcare providers within their insurance networks.</p>
                    <p className="smallText">The user-facing front-end of the application is being developed in ReactJS with HTML & CSS. There is a separate portal for healthcare providers which is being developed in Django. </p>
                    <p className="smallText">Our back-end is a Linux server running Apache, with an SQL data layer, and a PHP middle layer to connect everything.</p>
                </div>
                <div className="smallTextParagraph">
                    <p className="smallText">I have also worked on several portfolio pieces! In September 2024, I developed a fantasy football app called <a href="http://bagleycode.com/check-with-me">"Check with Me"</a> that shows users their fantasy players' injury statuses at any time.</p>
                    <p className="smallText">The front-end of the application was written in ReactJS, the back-end of the application is a RESTful API written in NodeJS/Express which itself calls the Google Search API, and the back-end was deployed on Vercel.</p>
                </div>
            </div>
        </div>
    );
}

export default SoftwarePortfolio;