import './Contact.css';
import './SharedStyles.css';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

function Contact() {
    const [ userInputName, setUserInputName ] = useState("");
    const [ userInputPhone, setUserInputPhone ] = useState("");
    const [ userInputEmail, setUserInputEmail ] = useState("");
    const [ userInputComment, setUserInputComment ] = useState("");

    const handleEmail = () => {
        if (userInputName !== "" && userInputPhone !== "" && userInputEmail !== "") {
            emailjs.send("service_rkrqlk6","template_pljw10k",{
                name: userInputName,
                phone: userInputPhone,
                email: userInputEmail,
                message: userInputComment
                },  "NrPq2ifCGEZoadwFX");
        }
        
        
    }

    return (
        <div className="fadedInOutlet" id="screen">
            <div className="screenHeading">
                <h1 className="bigText">Contact Me</h1>
                <p className="smallText" id="contactText">Thank you for visiting BagleyCode.com! If you would like to speak with me about developing a website or web application for your business, please fill out your contact information, and I will get back to you as soon as possible!</p>
                <form id="contactForm">
                    <input type="text" className="contactFormInput" name="name" onChange={(e) => setUserInputName(e.target.value)} placeholder="Your name..." required />
                    <input type="text" className="contactFormInput" name="email" onChange={(e) => setUserInputEmail(e.target.value)} placeholder="Your email address..." required />
                    <input type="text" className="contactFormInput" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890" onChange={(e) => setUserInputPhone(e.target.value)} required />
                    <textarea className="contactFormInput" id="contactFormTextArea" name="message" rows="4" cols="50" wrap="hard" placeholder="Feel free to write a message here!" onChange={(e) => setUserInputComment(e.target.value)} />
                    <input type="submit" className="contactFormInput" id="contactFormSubmitBTN" onClick={handleEmail}></input>
                </form>
            </div>
        </div>
    );
}

export default Contact;