import './DateMe.css';
import gondorMe from './gondorMe.jpg';
import returnMe from './returnMe.jpg';
import rohanMe from './rohanMe.jpg';
import snowMe from './snowMe.jpg';

export default function DateMe() {
    return (
        <div id="parentDiv_flex">
            <h1>Hello there! My name is Matt!</h1>
            <p>This is my "Date Me Doc". If you enjoy reading, that's a good sign! Keep reading below!</p>
            <div id="childDiv_flex">
                <div id="parentDiv_grid">
                    <img src={gondorMe} alt="This is me at my house in November 2023, rocking Aragon's Dunedain banner"/>
                    <img src={snowMe} alt="This is me outside in Beaverton circa February 2021"/>
                    <img src={returnMe} alt="This is me at a Timbers match in the summer of 2023"/>
                    <img src={rohanMe} alt="This is me at my house in November 2023, rocking a Rohan flag"/>
                </div>
                <div id="basics">
                    <h1>Basics:</h1>
                    <ul>
                        <li>He/him, ace, born 1992</li>
                        <li>6'1" & 200 lbs</li>
                        <li>Software developer, formerly a radio broadcaster</li>
                        <li>I currently live in the Portland, OR metro area</li>
                        <li>Neurodiverse and INTJ</li>
                        <li>Previously married. We were together from August 2015 to February 2023.</li>
                        <li>Looking for a monogamous relationship with a woman, or man, around my age (+/- 5 years)</li>
                    </ul>
                </div>
                <div id="looking">
                    <h1>What I'm looking for:</h1>
                    <ul>
                        <li>I'm seeking a monogamous relationship. I respect the poly folks out there, but poly is not for me! When I like something or someone, I commit to it.</li>
                        <li>I'm a passionate romantic and I'm looking for someone similar.</li>
                        <li>I want to start a family someday. If we have long term potential, then our future goals need to align.</li>
                        <li>I get along best with other neurodiverse folks. It's not a requirement, but it helps!</li>
                        <li>I believe that our personalities are way more important than our physical appearances or sexual preferences.</li>
                        <li>You don't need to be a sports fan, but my fandom is non-negotiable. I love going to Timbers/Thorns matches on Saturdays, and watching Las Vegas Raiders football on Sundays.</li>
                    </ul>
                </div>
                <div id="passions">
                    <h1>What I'm like:</h1>
                    <ul>
                        <li>My dream vacation is a cabin in the woods, with a cozy fireplace, and plenty of books.</li>
                        <li>My favorite food is Mexican and my favorite meal is a simple plate of chorizo con papas with a side of rice.</li>
                        <li>I get way into new hobbies.</li>
                        <li>I'm loyal to my friends & loved ones.</li>
                        <li>I can't guarantee that we'll get along, but I can guarantee that your cats will love me.</li>
                    </ul>
                </div>
                <div id="friends">
                    
                </div>
                <div id="contact">
                    <h1>Do you like what you've read here? Please tell!</h1>
                    <ul>
                        <li>My email address is MBagleyRadio@gmail.com</li>
                        <li>My twitter handle is @BagleySports. No, I'll never call Twitter "X".</li>
                        <li><a href="https://www.facebook.com/matthew.bagley.7902">Here is a link to my Facebook profile.</a> Send me a friend request!</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}