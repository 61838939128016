import './WebDesignPortfolio.css';
import './SharedStyles.css';

function WebDesignPortfolio() {
    return (
        <div className="fadedInOutlet" id="screen">
            <div className="screenHeading">
                <h1 className="bigText">Hi! I'm Matt!</h1>
                <h3 className="mediumText">I am a freelance web developer in Oregon, seeking work!</h3>
                <div className="smallTextParagraph">
                    <p className="smallText">In Spring 2024, I designed <a href="http://macdonaldsurveying.com">MacDonaldSurveying.com</a> for Leland MacDonald & Associates, a local land surveying firm. This included mobile-friendly and tablet-friendly versions of their site. </p>
                    <p className="smallText">Their website was developed with my preferred "tech stack", the tools that software developers use to create a web app: I used ReactJS on the front-end, Linux & Apache on the back-end, an SQL data layer, and a PHP middle layer to tie everything together!</p>
                    <p className="smallText">Previously, Leland MacDonald & Associates paid Dex.com thousands of dollars a year to maintain their website. My simple & clean website re-design cost them a fraction of that cost. If you are interested in learning how my work can save your business money, please contact me!</p>
                </div>
            </div>
        </div>
    );
}

export default WebDesignPortfolio;