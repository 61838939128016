import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div id="rootPage">
      <nav id="navParent">
        <div className="navChild"><Link className="navText" to="/">Home</Link></div>
        <div className="navChild"><Link className="navText" to="about-me">About</Link></div>
        <div className="navChild"><Link className="navText" to="web-design">Web Design</Link></div>
        <div className="navChild"><Link className="navText" to="web-apps">Apps</Link></div>
        <div className="navChild"><Link className="navText" to="contact-me">Contact</Link></div>
      </nav>
        <Outlet/>
    </div>
    
  );
}

export default App;
