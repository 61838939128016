import './TeamDreams.css';

export default function TeamDreams() {
    return (
        <div id="bg">
            <div id="banner">
                <h1>TeamDreams</h1>
                <h3>Build your dream starting five!</h3>
            </div>
            <div id="grid">
                <div class="entry" id="entry1">

                </div>
                <div class="entry" id="entry2">

                </div>
                <div class="entry" id="entry3">

                </div>
                <div class="entry" id="entry4">

                </div>
                <div class="entry" id="entry5">

                </div>
            </div>
            <div id="search">
                <button id="searchBtn">Search</button>
                <textarea id="searchBar" rows="1" cols="64"></textarea>
            </div>
        </div>
    );
}