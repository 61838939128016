import './CheckWithMe.css';
import { useState, useEffect } from 'react';

export default function CheckWithMe() {
    // values
    const [player, setPlayer] = useState(null); 

    // booleans
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (submitted === true) {
            requestInjuryReport();
        }
    }, [submitted]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (submitted !== true) {
            setSubmitted(true);
        } else {
            setSubmitted(false);
        }
    }

    const requestInjuryReport = () => {
        let index = 0;
        const nameArray = player.split(" ");
        let finalAnswer = `${player} is ruled AVAILABLE`;

        fetch(`https://check-with-me-be.vercel.app/?first=${nameArray[0]}&last=${nameArray[1]}`
        ).then(res => {
            console.log(`We're in the res, pre-json`)
            return res.json();
        }).then(data => {
            console.log(`We're in the res, post-json`)
            if (data.organic_results[index].snippet.includes("out") || data.organic_results[index].snippet.includes("OUT") || data.organic_results[index].title.includes("OUT") || data.organic_results[index].title.includes("out")) {
                finalAnswer = `${player} is ruled OUT`;
            }
        }).catch(error => {
            console.log(`Error: ${error}`);
        })
        alert(finalAnswer);
    }

    return (
    <div id="cwmBG">
        <div id="cwmBanner">
            <p id="cwmBannerHeader">Check With Me</p>
            <p id="cwmBannerSubText">Your last-minute fantasy football helper</p>
        </div>
        <div id="cwmPage">
            <div id="cwmPageAbout">
                <p><span className="italic">Check With Me</span> is a bare-bones, no-frills app developed by Matt Bagley, with a simple, specific purpose.</p>
                <p>Register, enter your team's lineup, and <span className="italic">Check With Me</span> will tell you if your players are OUT or ACTIVE on gameday.</p>
            </div>
            <div id="cwmPageForm">
                <form>
                    <div className="cwmFormElement">
                        <label for="player">Player</label>
                        <input className="cwmInputField" type="text" id="player" name="player" value={player} onChange={(e)=> setPlayer(e.target.value)}/>
                    </div>
                    <div id="submitBtnForCWM">
                        <input type="submit" onClick={(e) => handleSubmit(e)}/>
                    </div>
                </form>
            </div>
        </div>
    </div>
    );
}