// React Framework and React Router library
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';

// Imports for the Route Tree - the Parent, "App"
import App from './App.js';

// Imports for the Route Tree - the child routes of the BagleyCode.com index
import HomePage from './BagleyCode/HomePage.js';
import About from './BagleyCode/About.js';
import SoftwarePortfolio from './BagleyCode/SoftwarePortfolio.js';
import WebDesignPortfolio from './BagleyCode/WebDesignPortfolio.js';
import Contact from './BagleyCode/Contact.js';

// Imports for the Route Tree - alternate routes from the BagleyCode.com site
import DateMe from './MB_DateMeDoc/DateMe.js';
import TeamDreams from './TeamDreams/TeamDreams.js';
import CheckWithMe from './CheckWithMe/CheckWithMe.js';

function RouteIndex() {
  const router = createBrowserRouter(createRoutesFromElements(
    <>
      <Route path="/" element={ <App/> }>
        <Route index element={ <HomePage/> }></Route>
        <Route path="about-me" element={ <About/> }></Route>
        <Route path="web-apps" element={ <SoftwarePortfolio/> }></Route>
        <Route path="web-design" element={ <WebDesignPortfolio/> }></Route>
        <Route path="contact-me" element={ <Contact/> }></Route>
      </Route>
      <Route path="dateMe" element={ <DateMe/> }></Route>
      <Route path="team-dreams" element={ <TeamDreams/> }></Route>
      <Route path="check-with-me" element={ <CheckWithMe/> }>
      
      </Route>
    </>
    )
  );
  
  return (
    <div className="RouteIndex">
      <RouterProvider router={router} />
    </div>
  );
}

export default RouteIndex;