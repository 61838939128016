import './SharedStyles.css';
import './Homepage.css';
import snowMe from './images/snowMe.jpg';

function HomePage() {
    return (
        <div className="fadedInOutlet" id="screen">
            <div className="screenHeading">
                <h1 className="bigText">Hi! I'm Matt!</h1>
                <img src={snowMe} id="homepageIMG" alt="placeholder"/>
                <h3 className="mediumText">I am a freelance web developer in Oregon, seeking work!</h3>
            </div>
        </div>
    );
}

export default HomePage;